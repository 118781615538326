<template>
  <b-modal
    id="share-modal"
    ref="share-setting-modal"
    hide-footer
    size="md"
    title="ตั้งค่า แบ่งหุ้น/เก็บของ"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="ตั้งค่าให้"
      >
        <b-form-input
          type="text"
          placeholder="ชื่อใช้งาน"
          disabled
          v-model="accountUsername"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="หวย"
      >
        <b-form-input
          type="text"
          placeholder="หวย"
          disabled
          v-model="groupTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ได้รับส่วนแบ่ง (%)"
      >
        <b-form-input
          type="text"
          placeholder="ได้รับ %"
          disabled
          :value="refReceive | valFormat"
        ></b-form-input>
      </b-form-group>

      <div class="row">
        <div class="col">
          <b-form-group
            label="แบ่งให้เอเย่นต์ (%)"
          >
            <b-form-select
              :options="shareOptions"
              v-model="input.share.receive"
              size="md"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="col">
          <b-form-group
            label="เก็บของเข้าตัว (%)"
          >
            <b-form-input
              type="text"
              placeholder="ได้รับ %"
              disabled
              :value="keepValue | valFormat"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="my-1">
        <b-form-checkbox
          v-model="input.share.over"
          :value="true"
          :unchecked-value="false"
        >
          เก็บของเกิน
        </b-form-checkbox>
      </div>

      <div class="d-flex justify-content-end mt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import AccountSettingService from "@/services/AccountSettingService"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

const defaultInput = {
  accountId: null,
  username: null,
  groupId: null,
  groupTitle: null,
  share: {
    receive: 0,
    referral: 0,
    over: false
  }
}

export default {
  name: 'EditShareModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: null
    },
    refData: {
      type: Object
    }
  },
  data() {
    return {
      needReload: false,
      isProcess: false,
      input: defaultInput
    }
  },
  computed: {
    accountUsername() {
      return this.data?.username
    },
    groupTitle() {
      return this.data?.groupTitle
    },
    refReceive() {
      if(!this.data)
        return 0

      return this.refData.receive
    },
    shareOptions() {
      const maxShare = this.refReceive
      let sharePercents = []
      for(let i=maxShare; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }
      return sharePercents
    },
    keepValue() {
      const keep = this.refReceive - this.input.share.receive
      this.input.share.referral = keep
      return keep
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      if(this.data) {
        this.input = JSON.parse(JSON.stringify(this.data))
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.input = defaultInput
    },
    showModal() {
      this.$refs['share-setting-modal'].show()
    },
    hideModal() {
      this.$refs['share-setting-modal'].hide()
    },
    handleSubmit() {
      AccountSettingService.updateAccountSettingShare(this.input)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  },
  filters: {
    valFormat(val) {
      return val.toFixed(1)
    }
  }
}
</script>
